@import "./button.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  width: 100%;
  color: #333;
}

.title {
  margin: 0;
  width: 100%;
  padding-top: 80px;
  line-height: 1.15;
  font-size: 48px;
}

.title,
.description {
  text-align: center;
}
